@import 'account-flagship_tasting_room.scss';

.b-account_card{
    &-defaultaddress{
        svg{
            height: 12px;
            width: 12px;
        }
    }
    &-footer {
        &.b-account_card-addressfooter{
            margin-top: 30px;
        }
    }
}

.b-account_nav-item{
    color: #3F4142;
    font-weight: 300;
    text-transform: capitalize;
    &.m-active {
    color: $color-primary;
    border-bottom:1px solid $color-primary;
        &+li.b-account_nav-item{
            border-top: 0;
        }
    }
    &_link{
        &:after{
            mask: url(svg-icons/sprite.svg#arrow-right);
        }
    }
}

.b-track_notification{
	&-inner{
		background-color: $color-primary-background;
		color: $color-primary;
        border-color: $color-primary;
	}
}